/* Website Stylesheet */

@import url('https://use.typekit.net/pct0wai.css');

$border-radius-md: 5px;

$color-orange: #f18802;
$color-gray: #7f7f7f;

:root {
	--color-gray-light: #c4c4c4;
	--color-gray: #7f7f7f;
	--color-gray-dark: #333333;
	--color-offwhite: #f8f9fa;
	--color-white: #fff;
	--font-size-md: 18px;
	--jumbotron-padding-y: 3rem;
}

html {
	position: relative;
	min-height: 100%;
}

body {
	background-color: #fafafa;
	font-family: 'aktiv-grotesk', sans-serif;
	margin: 0;
	margin-bottom: 180px; /* Margin bottom by footer height */
}

/* Navigation Bar */

.navbar {
	background-color: transparent;
}

.app-container {
	padding: 0px 0 180px 0;
	margin-top: -80px;
	.service {
		.tr-images {
			text-align: center;
			img {
				width: 90%;
				max-width: 800px;
				margin-bottom: 10px;
			}
		}
		.content {
			h1 {
				font-size: 2.5em;
			}
			h3 {
				font-weight: 400;
			}
			max-width: 1000px;
			margin: 0 auto;
		}
		.images {
			text-align: center;
			img {
				width: 80%;
				max-width: 500px;
				margin-right: 3px;
				margin-bottom: 3px;
			}
		}
		margin-top: 30px;
	}
	.contact {
		margin-top: 60px;
	}
}

.jumbotron {
	background-color: var(--white);
	padding-top: 50px;
	border-radius: 0;
	background-size: cover;
	background-position: center;
	.container {
		button: {
			background-color: $color-orange;
			color: white;
		}
		padding: 0;
	}
	&.home {
		background-size: cover;
		background-image: url('../resources/images/backgrounds/home.png');
		color: white;
		padding: 8em 0 12em 0;
		margin: 0;
	}
	&.paintless-cover {
		background-size: cover;
		background-image: url('../resources/images/backgrounds/paintless.jpg');
		background-position: center 50%;
		color: black;
		padding: 7em 0;
		margin: 0;
	}
	&.traditional-cover {
		background-size: cover;
		background-image: url('../resources/images/backgrounds/traditional.jpg');
		color: black;
		padding: 7em 0;
		margin: 0;
	}
	&.repair-cover {
		background-size: cover;
		background-position: center;
		background-image: url('../resources/images/backgrounds/repair.jpg');
		color: black;
		padding: 7em 0;
		margin: 0;
	}
	&.contact-cover {
		background-size: cover;
		background-image: url('../resources/images/backgrounds/contact.jpg');
		color: black;
		padding: 7em 0;
		margin: 0;
	}
}

.navbar {
	img {
		height: 40px;
	}
}

.card.withImage {
	-webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
	-moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
	border-radius: 0;
	border-width: 0;
	min-height: 400px;
	.card-img-top {
		padding: 3rem;
		max-height: 240px;
	}
	.card-title {
		font-size: 1.7rem;
		font-weight: 700;
		text-align: center;
		margin-top: -35px;
		margin-bottom: 25px;
	}
	.card-body {
		text-align: center;
	}
}

/* -- SECTIONS -- */

section.withImage {
	font-size: 16px;
	margin: 20px auto;
	max-width: 800px;
	h1 {
		padding: 8px;
	}
	h2 {
		padding: 0 8px;
	}
	.card-columns {
		padding-top: 2rem;
		max-width: 800px;
		margin: 0 auto;
		margin-top: -170px !important;
		margin-bottom: 50px;
		.card {
			display: block !important;
		}
	}
}

/* -- PRODUCT CARDS -- */

div.product {
	&.paintless {
		background-image: url('../resources/images/services/paintless.png');
	}
	&.traditional {
		background-image: url('../resources/images/services/traditional.svg');
	}
	&.damage {
		background-image: url('../resources/images/services/damage-repair.png');
		background-position: center;
	}
	background-size: cover;
	margin: 0 auto;
	margin-bottom: 280px;
	max-width: 800px;
	div {
		background-color: white;
		bottom: -14em;
		-webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
		-moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
		box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
		box-sizing: border-box;
		left: 4em;
		padding: 20px;
		padding-bottom: 25px;
		position: relative;
		max-width: 300px;
		ul {
			padding-inline-start: 20px;
		}
		h2 {
			padding-bottom: 12px;
		}
		p {
			padding-bottom: 24px;
		}
	}
}

/* ---- TESTIMONIALS ---- */

.testimonials {
	background-color: white;
	-webkit-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
	-moz-box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.11);
	box-sizing: border-box;
	padding: 20px;
	margin-bottom: 45px !important;
	text-align: center;
	max-width: 600px;
	overflow: hidden;
	button {
		margin-top: 20px;
	}
}

/* ---- PARTNERS ---- */

.partners {
	overflow: hidden;
	h2 {
		text-align: center;
		text-transform: uppercase;
		margin-bottom: 20px;
	}
	.row {
		justify-content: center;
	}
	img {
		height: 100px;
		margin-top: 30px;
		margin-right: 36px;
	}
}

/* ---- CONTACT PAGE ---- */

.contact {
	.content {
		max-width: 350px;
		margin: 0 auto;
	}
}

.footer {
	background-color: rgba(0, 0, 0, 0.6);
	bottom: 0;
	color: white;
	line-height: 60px;
	text-align: center;
	padding: 40px 0;
	padding-top: 60px;
	position: absolute;
	width: 100%;
	a {
		color: white;
		font-size: 16px;
		text-decoration: underline;
		line-height: initial;
		&:hover {
			color: lightgray;
		}
	}
	p {
		margin: 0;
		margin-bottom: 10px;
		padding: 0;
		line-height: initial;
	}
	img {
		height: 90px;
		margin-bottom: 30px;
	}
	div {
		padding: 0 10px;
		max-width: 600px;
		margin: 0 auto;
	}
	div.social-media > img {
		background-color: white;
		border-radius: 10px;
		color: white;
		height: 30px;
		padding: 3px;
		margin-right: 8px;
		&:hover {
			background-color: #c4c4c4;
		}
		:last-child {
			margin-right: 0;
		}
	}
}
footer::after {
	content: '';
	background-image: url('../resources/images/backgrounds/footer_bg.svg');
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	position: absolute;
	z-index: -1;
}

h1,
h2,
h3,
h4,
h5,
p {
	margin-bottom: 5px;
	overflow-wrap: break-word;
}

h1 {
	font-size: 3rem;
	font-weight: 700;
}
h2 {
	font-size: 2rem;
	font-weight: 700;
}
h3 {
	font-size: 1.4rem;
}
h4 {
	font-size: 1.2rem;
}
h5 {
	font-size: 1.1rem;
}
p {
	font-size: 1rem;
}

a {
	color: #222222;
	font-size: var(--font-size-md);
	text-decoration: none;
	transition: all 0.2s ease;
	padding-bottom: 7px;
}
a:hover {
	color: black;
}

.navbar-brand {
	width: 150px;
}

.navbar-light .navbar-toggler {
	border-style: none;
}

.product-card {
	background-color: var(--color-offwhite);
	display: flex;
	flex-basis: 600px;
	flex-direction: row;
	font-size: 16px;
	margin: 0 auto;
	margin-bottom: 40px;
	max-height: 400px;
	max-width: 700px;
	.image {
		background-color: lightgray;
		display: flex;
		position: relative;
		flex: 1;
		flex-direction: column;
		min-width: 50%;
		width: 100%;
		a {
			background: rgba(0, 0, 0, 0.22);
			border: 2px solid #e3e3e3;
			position: absolute;
			top: 85%;
			left: 50%;
			transform: translate(-50%, -50%);
			margin: 0 auto;
			width: 150px;
			padding: 0 5px;
			text-align: center;
			&:hover {
				background: var(--color-white);
				border-color: var(--color-white);
			}
			button {
				background: unset;
				&:hover {
					color: $color-orange;
				}
			}
		}
		img {
			display: block;
			height: 100%;
			width: auto;
			max-width: 100%;
			max-height: 100%;
			object-fit: cover;
		}
	}
}

.product-card .content {
	display: flex;
	flex: 1;
	flex-direction: column;
	box-sizing: border-box;
	padding: 20px;
}
.product-card p {
	margin: 12px 0 20px 0;
	line-height: 1.3em;
}

button {
	border: none;
	line-height: 1em;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 16px;
	padding: 14px 22px;
	transition: all 0.2s ease;
	&.primary {
		background-color: $color-orange;
		color: white;
		&:hover:enabled {
			background-color: lighten($color-orange, 5);
		}
	}
	&.secondary {
		background-color: white;
		border: 2px solid $color-orange;
		color: $color-orange;
		&:hover:enabled {
			background-color: $color-orange;
			color: white;
		}
	}
	&:disabled {
		background-color: $color-gray;
		cursor: not-allowed;
	}
}

form {
	max-width: 300px;
	margin: 0 auto;
}

.form-group {
	display: flex;
	flex-direction: column;
	margin: 20px 0;
}
.form-group label {
	font-size: 18px;
	margin-bottom: 10px;
}
.form-group input,
.form-group textarea {
	border-radius: 1px;
	border-color: darkgray;
	border-style: solid;
	border-width: 1px;
	font-size: 18px;
	padding: 10px 12px;
}

/* HR widths TODO: Should this be here? */

h1 {
	border-inline-start-style: solid;
	border-inline-start-width: 4px;
	border-inline-start-color: $color-orange;
	padding-left: 1rem;
}

@media only screen and (max-width: 850px) {
	.card-columns {
		display: none;
	}
	.jumbotron {
		.container {
			padding: 0 5%;
		}
		&.home {
			&.container {
				max-width: 800px !important;
				margin: 0 auto;
			}
			padding: 8em 0;
		}
	}
}

@media only screen and (max-width: 750px) {
	.product-card {
		flex-direction: column;
		max-height: unset;
		max-width: 400px;
		.image {
			max-height: 300px;
			overflow: hidden;
		}
	}
	.app-container {
		padding: 0px 0 280px 0;
	}
}
